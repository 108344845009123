import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import { SocialIcon } from 'react-social-icons';
import './App.css';
import BackgroundText from './BackgroundText';
import successbee from './successbee.png';
import failbee from './failbee.png';
import paste from './paste.png';

const App = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyAtZ5s2KEVBugA7jlNPtC3BiG8NHlB5ThA",
    authDomain: "beesonbtc-e8d25.firebaseapp.com",
    projectId: "beesonbtc-e8d25",
    storageBucket: "beesonbtc-e8d25.appspot.com",
    messagingSenderId: "541164428249",
    appId: "1:541164428249:web:6a4c6d7be2bc23c53417c8",
    measurementId: "G-D9XS2Y44HB"
  };
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);
  const [textInput, setTextInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const handlePaste = async (e) => {
    e.preventDefault();
    const text = await navigator.clipboard.readText();
    setTextInput(text);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await checkWalletWhitelist(textInput);
      setLoading(false);

      if (result) {
        setSuccess(true);
        setFailure(false);
      } else {
        setSuccess(false);
        setFailure(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setSuccess(false);
      setFailure(true);
    }
  };

  const handleEnter = async (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      await handleSubmit(e);
    }
  }

  const checkWalletWhitelist = async (text) => {
    const walletQ = query(collection(db, "whitelist"), where("wallet", "==", text));
    const querySnapshot = await getDocs(walletQ);
    if (querySnapshot.size > 0) {
      logEvent(analytics, 'wallet_check', {result: "true"});
      return true;
    }
    logEvent(analytics, 'wallet_check', {result: "false"});
    return false;
  };

  return (
    <div className="app">
      <div className="container">
        <h1 className="heading">Check if you're on the <span style={{ color: "#FF9900" }}>beelist</span></h1>
        <form onSubmit={handleSubmit}>
          <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <span style={{alignItems: "center", display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: "8px"}}>
              <input type="text" className="textbox" placeholder="Enter your wallet" value={textInput} onChange={(e) => setTextInput(e.target.value)} onKeyDown={handleEnter}/>
              <button onClick={handlePaste} className='paste-button'><img src={paste} className='paste-icon' alt="paste"/></button>
            </span>
            <button type="submit" className="button" disabled={loading}>
              {loading ? 'Loading...' : 'Check'}
            </button>
          </div>
        </form>
        <div className='whitelist-info'>
          {success &&
            <>
              <div style={{ alignItems: "center", display: "flex", flexDirection: "column", margin: "8px", minWidth: "290px" }}>
                <p className="success-text" style={{ textAlign: "center" }}>Congrats!!! You're beelisted!!!!</p>
                <img className="successbee" src={successbee} alt='Success Bee' />
              </div>
              <div style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "start", fontSize: "28px", margin: "8px" }}>
                <p style={{fontSize: "36px"}}>To secure your spot:</p>
                <ol>
                  <li><a href='https://twitter.com/BeesOnBTC'>Follow @BeesOnBTC</a> on Twitter</li>
                  <li style={{lineHeight: "20px"}}>Join the Bees on Bitcoin Discord<br /><span style={{color: "gray", fontSize: "24px"}}>Coming Soon!</span></li>
                </ol>
              </div>
            </>
          }
          {failure && 
          <>
          <div style={{ alignItems: "center", display: "flex", flexDirection: "column", margin: "8px",  minWidth: "290px" }}>
            <p className="failure-text">You are not beelisted :(</p>
            <img className="successbee" src={failbee} alt='Failed Bee' />
          </div>
          <div style={{ alignItems: "center", display: "flex", flexDirection: "column", fontSize: "28px", margin: "8px" }}>
                <p style={{fontSize: "32px", textAlign: "center"}}>For Beelist Opportunities:</p>
                <ol>
                  <li><a href='https://twitter.com/BeesOnBTC'>Follow @BeesOnBTC</a> on Twitter</li>
                  <li style={{lineHeight: "20px"}}>Join the Bees on Bitcoin Discord<br /><span style={{color: "gray", fontSize: "24px"}}>Coming Soon!</span></li>
                </ol>
              </div>
          </>
          }
        </div>
        <div style={{alignItems: "center", display: "flex", flexDirection: "row", justifyContent: "center", bottom: "24px", position: "absolute", width: "120px", paddingTop: "50px", height: "50px"}}>
          <SocialIcon url='https://twitter.com/BeesOnBTC' target='_blank' />
        </div>
      </div>
      <BackgroundText />
    </div>
  );
};

export default App;
